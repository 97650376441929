import {deleteOrder} from '@/api/order'
import {mapActions, mapState} from 'vuex'

export default {
  mounted(){

  },
  computed:{
    is_edit(){
      let {order_no,isDraft} = this.$route.query||{}
      if(isDraft=='true'){
        return  1
      }
      if(!this.order_no)
        return  0
      return order_no!==this.order_no?1:0
    }
  },
  methods: {
    handlerDelete(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.mixinNetwork().deleteOrder()
      })
    },
    mixinNetwork(){
      return{
        deleteOrder:async (params)=>{
          params ={
            order_no:this.order_no
          }
          let {data} = await deleteOrder(params)
          this.setQuoteInformationForm(null)
          this.$router.push('/policy/draft')
        },
      }
    }
  }
}
