<template>
  <div class="insured-empolyee">
    <div class="flex">
      <v-title title="Insured Location"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="handlerAdd">
          Add Location
        </v-button>
      </div>
    </div>
    <div
      v-if="isSame"
      class="flex flex-right">
      <v-checkbox
        v-model="formData.same_as_correspondence_address"
        class="theme-blue"
        @change="handlerSame"></v-checkbox>
      <span>Same as Correspondence Address</span>
    </div>
    <el-form
      ref="form"
      :model="formData"
      :rules="rules">
      <div
        v-for="(item,index) in formData.insured_location "
        :key="index"
        class="card">
        <div class="flex flex-title">
          <span class="title">Insured Location {{index+1}}</span>
          <span
            class="title"
            @click="handlerDelete(index)">Deleted</span>
        </div>
        <address-form
          :ref="'address'+index"
          :formDataLock="formDataLock"
          :form="item"
          :disabled="formData.same_as_correspondence_address==1&&index==0"
          :index="index"
          @getFormData="getSubFormData"/>
      </div>
    </el-form>
  </div>
</template>

<script>
import AddressForm from '@/views/components/address-form'
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'

export default {
  name: 'insuredLocation',
  components: {
    AddressForm,
    // ConfirmInformation
  },
  mixins: [MixinOptions,MixinRules],
  props:{
    form:{
      type:Object,
      default:()=>({})
    },
    isSame:{
      type:Boolean,
      default:true
    }
  },
  data(){
    return{
      formDataLock:0,
      correspondence_address: {
        room: '',
        floor: '',
        block: '',
        building: '',
        street: '',
        district: '',
        area: '',
        region: ''
      },
      formData:{
        same_as_correspondence_address:0,
        insured_location:[
          { ...this.correspondence_address}
        ],
      },
      subForm:{
        address0:false
      }
    }
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      immediate: true,
      handler(val) {
        // if(this.formDataLock !== this.oldFormDataLock) {
        // this.oldFormDataLock = this.formDataLock
        let {same_as_correspondence_address} =val||{}
        this.formData.same_as_correspondence_address=same_as_correspondence_address||0
        if(val)
        {
          let {insured_location=[]} = val||{}
          if(insured_location.length<=0)
            return

          this.formData.insured_location = insured_location
          insured_location.forEach((item,index)=>{
            this.subForm['address'+index] =false
          })
        }
      }
    },
  },
  created(){
    // this.correspondence_address= JSON.stringify(this.correspondence_address)
  },
  methods:{
    handlerSame(){
      const {same_as_correspondence_address} = this.formData
      this.$emit('sameAddress', same_as_correspondence_address == 1 ? true : false)
    },
    sameAddress(data){
      const {same_as_correspondence_address} = this.formData
      let {correspondence_address} = data||{}
      if(data) this.formDataLock = this.formDataLock++
      if(same_as_correspondence_address==1)
        this.formData.insured_location[0] =correspondence_address
    },
    submitForm(formName){
      Object.keys(this.subForm).some(key => {
        this.subForm[key] = false
        let index = key.split('address')[1]
        this.$refs[key][0].submitForm(key,index)
      })
      if (Object.values(this.subForm).some(item => item === false)) {
        return
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('getFormData', formName,this.formData)
        }
      })
    },
    handlerAdd(){
      let params = JSON.parse(JSON.stringify(this.correspondence_address))
      this.formData.insured_location.push(params)
      let index= this.formData.insured_location.length>0?(this.formData.insured_location.length-1):0
      this.subForm['address'+index] = false
    },
    handlerDelete(index){
      if(index==0)
        return
      let change = this.formData.insured_location.length-1
      if (this.formData.insured_location.length <= 1) return
      this.formData.insured_location.splice(index,1)
      delete this.subForm['address'+change]
    },
    getSubFormData(form, data,index) {
      this.subForm[form] = true
      let {correspondence_address} = data||{}
      Object.assign(this.formData.insured_location[index], correspondence_address)
    },
  }
}
</script>

<style scoped lang="scss">

  .insured-empolyee {
    .flex-right {
      justify-content: flex-end!important;
      margin-bottom: 20px;

      ::v-deep .el-checkbox {
        margin-right: 8px;
      }

      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
        background: #076081 !important;
        border: 1px solid #076081 !important;

      }
    }
    .card {
      background: #EDF2F7;
      border-radius: 8px;
      color: #2D3748;
      margin-bottom: 20px;
      .flex-title{
        padding: 20px 20px 0 20px;
      }
    }

    .flex {
      justify-content: space-between;
      align-items: center;
    }
  }

</style>
