<template>
  <el-form
    ref="form"
    :rules="rules"
    :model="formData"
  >
    <!--    motor-->
    <div v-if="productID==10">
      <!--    private Commerial-->
      <el-row
        v-if="carType!==1004"
        :gutter="30"
        justify="space-around"
      >
        <v-row-col>
          <el-row
            class="row-bg"
            :gutter="30"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item">
                <span>Cover Type</span>
                <div class="car-type-radio">
                  <el-form-item prop="cover_type">
                    <v-radio-group
                      v-model="formData.cover_type"
                      :border="true"
                      :list="coverTypeOptions"
                      :disabled="carType===1003||isEdit"
                      @change="handlerPolicyData"
                    >
                    </v-radio-group>
                  </el-form-item>
                </div>
              </div>
            </v-row-col>
            <v-row-col
              v-if="formData.cover_type==2"
              :same="true"
              :same-number="24">
              <div class="form-item">
                <span>Estimated Vehicle Value</span>
                <el-form-item prop="estimated_vehicle_value">
                  <v-input
                    v-model="formData.estimated_vehicle_value"
                    money
                    prepend="HKD"></v-input>
                </el-form-item>
              </div>
            </v-row-col>
          </el-row>
        </v-row-col>
        <v-row-col>
          <div v-if="carType!==1003&&formData.cover_type!=1">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item">
                <span>Hire Purchase Owner</span>
                <el-form-item prop="hire_purchase_owner">
                  <div class="car-type-radio">
                    <v-radio-group
                      v-model.number="formData.hire_purchase_owner"
                      :border="true"
                      :list="yesNoOptions"
                    >
                    </v-radio-group>
                  </div>
                </el-form-item>
              </div>
            </v-row-col>

            <v-row-col
              v-if="formData.hire_purchase_owner==1"
              :same="true"
              :same-number="24">
              <div class="form-item">
                <span >Name of Hire Purchase Owner</span>
                <el-form-item prop="hire_purchase_owner_id">
                  <v-select
                    v-model="formData.hire_purchase_owner_id"
                    :list="globalOptions.hire_purchase "
                  ></v-select>
                </el-form-item>
                <el-form-item
                  v-if="formData.hire_purchase_owner_id==100"
                  prop="hire_purchase_owner_value">
                  <v-input
                    v-model="formData.hire_purchase_owner_value"
                  ></v-input>
                </el-form-item>
              </div>
            </v-row-col>

            <v-row-col

              :same="true"
              :same-number="24">
              <div class="form-item">
                <!--          <span>Policy send to Hire Purchase Owner</span>-->
                <!--          <v-date-picker></v-date-picker>-->
              </div>
            </v-row-col>
            <v-row-col
              v-if="formData.hire_purchase_owner==1"
              :same="true"
              :same-number="24">
              <div class="form-item">

                <span>Policy send to Hire Purchase Owner</span>
                <el-form-item prop="policy_send_to_hire_purchase_owner_date">
                  <v-date-picker v-model="formData.policy_send_to_hire_purchase_owner_date"></v-date-picker>
                </el-form-item>
              </div>
            </v-row-col>
          </div>
        </v-row-col>
      </el-row>
      <!--    motor trade-->
      <el-row
        v-else
        :gutter="30"
        justify="space-around">
        <v-row-col
          :same="true"
          :same-number="24"
        >
          <div class="form-item">
            <span>Plan</span>
            <div class="car-type-radio">
              <el-form-item>
                <v-radio-group
                  v-model="formData.cover_type"
                  :border="true"
                  :list="planOptions"
                  @change="handlerPolicyData"
                >
                </v-radio-group>
              </el-form-item>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="form-item">
            <span>Demonstration Extension Risk Cover</span>
            <div class="car-type-radio">
              <el-form-item>
                <v-radio-group
                  v-model="formData.demonstration_extension_risk_cover"
                  :border="true"
                  :list="yesNoOptions"
                >
                </v-radio-group>
              </el-form-item>
            </div>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="6">
          <div class="form-item">
            <span>Insured Amount (HKD)</span>
            <el-form-item>
              <div class="car-type-radio">
                <v-input v-model="formData.insured_amount"></v-input>
              </div>
            </el-form-item>
          </div>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="6">
          <div class="form-item">
            <span>Premium (HKD)</span>
            <el-form-item>
              <v-input v-model="formData.premium"></v-input>
            </el-form-item>
          </div>
        </v-row-col>
      </el-row>
    </div>
    <div v-if="productID==11">
      <el-row  :gutter="30">
        <v-row-col>
          <v-row-col same>
            <div class="form-item">
              <span>Scope of Work</span>
              <el-form-item prop="remark">
                <v-input
                  v-model="formData.scope_of_work"
                  type="textarea"
                  :placeholder="'Text'"
                  :rows="4"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col same>
            <div class="form-item">
              <span>Work Site Location</span>
              <el-form-item  prop="remark">
                <v-input
                  v-model="formData.work_site_location"
                  type="textarea"
                  :placeholder="'Address'"
                  :rows="4"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
          >
            <div class="form-item">
              <span>Contract Value</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.contract_value"
                  prepend="HKD"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Section 1 - Material Damage</span>
              <el-form-item>
                <div class="car-type-radio">
                  <v-radio-group
                    v-model.number="formData.section_1"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerRisk"
                  >
                  </v-radio-group>
                </div>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="formData.section_1==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Insured Amount (Material Damage)</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.insured_amount_material_damage"
                  money
                  prepend="HKD"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.section_1==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Material Damage)</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.premium_material_damage"
                  money
                  prepend="HKD"
                  :disabled="isEndorsement"
                  @change="handlerRisk"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Section 2 - Third Party Liability</span>
              <el-form-item prop="section_2">
                <div class="car-type-radio">
                  <v-radio-group
                    v-model.number="formData.section_2"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerRisk"
                  >
                  </v-radio-group>
                </div>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="formData.section_2==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Insured Amount (Third Party Liability)</span>
              <el-form-item >
                <!--                <v-select-->
                <!--                  v-model.number="formData.insured_amount_third_party_id"-->
                <!--                  :list="globalOptions.risks_insurer_amount">-->
                <!--                </v-select>              -->
                <v-input
                  v-model.number="formData.insured_amount_third_party"
                  money
                  append="Million"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.section_2==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Third Party Liability)</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.premium_third_party"
                  prepend="HKD"
                  :disabled="isEndorsement"
                  @change="handlerRisk"></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
    </div>
    <div v-if="productID==13">
      <!--    home-->
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col same>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item form-item-flex">
              <span class="span-none"></span>
              <span>Insured Amount (HKD)</span>
              <span>Premium (HKD)</span>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Home Contents All Risk</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.home_contents_all_risk.insured_amount"
                  money

                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.home_contents_all_risk.premium"
                  money
                  @change="handlerHomePremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Personal Accident</span>
              <el-form-item >
                <v-input
                  v-model.number="formData.personal_accident.insured_amount"
                  money

                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.personal_accident.premium"
                  money
                  @change="handlerHomePremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Personal Liability</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.personal_liability.insured_amount"
                  type="number"

                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.personal_liability.premium"
                  money
                  @change="handlerHomePremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>
      <el-row
        :gutter="20"
        justify="space-around"
      >
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Domestic Helpers</span>
              <el-form-item prop="hire_purchase_owner">
                <div class="car-type-radio">
                  <v-radio-group
                    v-model.number="formData.optional_benefit.domestic_helpers"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerChange"
                  >
                  </v-radio-group>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.optional_benefit.domestic_helpers"

            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Domestic Helper)</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model.number="formData.optional_benefit.domestic_helpers_premium"
                  prepend="HKD"
                  money
                  @change="handlerChange"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Building Structure</span>
              <el-form-item prop="hire_purchase_owner">
                <div class="car-type-radio">
                  <v-radio-group
                    v-model.number="formData.optional_benefit.building"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerHomePremium"
                  >
                  </v-radio-group>
                </div>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.optional_benefit.building"

            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Insured Amount (Building)</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model.number="formData.optional_benefit.building_insured_amount"
                  prepend="HKD"
                  money
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            v-if="formData.optional_benefit.building"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Building)</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model.number="formData.optional_benefit.building_premium"
                  prepend="HKD"
                  money
                  @change="handlerHomePremium"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span>Other</span>
              <el-form-item prop="hire_purchase_owner">
                <div class="car-type-radio">
                  <v-radio-group
                    v-model.number="formData.optional_benefit.other"
                    :border="true"
                    :list="yesNoOptions"
                    @change="handlerHomePremium"
                  >
                  </v-radio-group>
                </div>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="formData.optional_benefit.other==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Other</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model="formData.optional_benefit.other_content"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>

          <v-row-col
            v-if="formData.optional_benefit.other==1"
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Other)</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model.number="formData.optional_benefit.other_premium"
                  prepend="HKD"
                  money
                  @change="handlerHomePremium"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>
    </div>
    <div v-if="productID==15">
      <!--    business-->
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col same>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item form-item-flex">
              <span class="span-none"></span>
              <span>Insured Amount (HKD)</span>
              <span>Premium (HKD)</span>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Contents</span>
              <el-form-item >
                <v-input
                  v-model="formData.contents.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model="formData.contents.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Business Interruption Insurance </span>
              <el-form-item >
                <v-input
                  v-model="formData.business_interruption_insurance.insured_amount"
                  money

                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model="formData.business_interruption_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Money Insurance</span>
              <el-form-item>
                <v-input
                  v-model.number="formData.money_insurance.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.money_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Fidelity Guarantee Insurance </span>
              <el-form-item>
                <v-input
                  v-model.number="formData.fidelity_guarantee_insurance.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.fidelity_guarantee_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Personal Accident Assault Insurance  </span>
              <el-form-item>
                <v-input
                  v-model.number="formData.personal_accident_assault_insurance.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.personal_accident_assault_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Public Liability Insurance  </span>
              <el-form-item>
                <v-input
                  v-model.number="formData.public_liability_insurance.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.public_liability_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item ">
              <span>Employees’ Compensation Insurance </span>
              <el-form-item>
                <v-input
                  v-model.number="formData.employees_compensation_insurance.insured_amount"
                  money
                />
              </el-form-item>

              <el-form-item >
                <v-input
                  v-model.number="formData.employees_compensation_insurance.premium"
                  money
                  :disabled="isEndorsement"
                  @change="handlerPremium"
                />
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col
          :same="true"
          :same-number="24">
          <div class="hr-inline"></div>
        </v-row-col>
      </el-row>

    </div>
    <div v-if="productID==17">
      <!--    private Commerial-->
      <el-row
        class="row-bg flex-inline"
        :gutter="20"
        justify="space-around">
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Scope of Work</span>
              <el-form-item prop="scope_of_work">
                <v-input
                  v-model="formData.scope_of_work"
                  type="textarea"
                  :placeholder="'Text'"
                  :rows="4"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Work Site Location</span>
              <el-form-item prop="work_site_location">
                <v-input
                  v-model="formData.work_site_location"
                  type="textarea"
                  :placeholder="'Text'"
                  :rows="4"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Contract Value</span>
              <el-form-item prop="contract_value">
                <v-input
                  v-model.number="formData.contract_value"
                  prepend="HKD"
                  money
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
        <v-row-col>
          <v-row-col
            :same="true"
            :same-number="24">
            <div class="form-item">
              <span >Premium (Employees' Compensation Insurance)</span>
              <el-form-item prop="hire_purchase_owner_id">
                <v-input
                  v-model.number="formData.premium"
                  prepend="HKD"
                  money
                  @change="handlerConstraction"
                ></v-input>
              </el-form-item>
            </div>
          </v-row-col>
        </v-row-col>
      </el-row>

    </div>
  </el-form>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapState,mapActions} from 'vuex'
export default {
  name: 'coverage',
  mixins: [MixinOptions,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType:{
      type: Number,
      default: 0
    },
    policyType:{
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      carSelect: '',
      coverType:0,
      formData:{
        coverType:0,
        cover_type: 1, //1 third party 2 comprehensive
        hire_purchase_owner: 1, //1 yes,no-2
        hire_purchase_owner_id: '',
        estimated_vehicle_value: null,
        policy_send_to_hire_purchase_owner_date: '',
        premium:'',
        insured_amount:'',
        demonstration_extension_risk_cover:1,
        other:1,
        //constract Risk
        scope_of_work:'',
        work_site_location:'',
        contract_value:'',
        section_1:1,
        insured_amount_material_damage:0,
        premium_material_damage:0,
        section_2:1,
        insured_amount_third_party_id:'',
        insured_amount_third_party:'',
        premium_third_party:'',
        hire_purchase_owner_value:'',
        home_contents_all_risk:{
          insured_amount:'',
          premium:''
        },
        personal_accident:{
          insured_amount:'',
          premium:'',
        },
        personal_liability:{
          insured_amount:'',
          premium:''
        },
        optional_benefit:{
          domestic_helpers:'',
          other:'',
          domestic_helpers_premium:'',
          other_content:'',
          building:'',
          other_premium:'',
          building_insured_amount:'',
          building_premium:''
        },
        contents:{
          insured_amount:'',
          premium:''
        },
        business_interruption_insurance:{
          insured_amount:'',
          premium:''
        },
        money_insurance:{
          insured_amount:'',
          premium:''
        },
        fidelity_guarantee_insurance:{
          insured_amount:'',
          premium:''
        },
        personal_accident_assault_insurance:{
          insured_amount:'',
          premium:''
        },
        public_liability_insurance:{
          insured_amount:'',
          premium:''
        },
        employees_compensation_insurance:{
          insured_amount:'',
          premium:''
        }

      }
    }
  },
  computed:{
    ...mapState('quotation', ['productID']),
    ...mapState('coverage', ['coverageData','riskPremium']),
    isEndorsement()
    {
      return this.form.policy_type ==203||this.$route.query.isEndorsement=='true'
    },
    isEdit(){
      let {is_edit='',edit_all} = this.form||{}
      if(edit_all)
        return false
      return is_edit?true:false
    },
  },
  watch:{
    //MOTOR CYCLE 只有第三方，其他车辆有全保功能
    carType:{
      immediate:true,
      handler(val){
        if(val===1003)
          this.formData.cover_type = 1
      }
    },
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if(val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.formDataLock !== this.oldFormDataLock) {
          // 觸發更新就賦值，避免二次更新
          this.oldFormDataLock = this.formDataLock
          let {coverage = {}} = val || {}
          this.setPremium(null)
          this.setRiskPremium(null)
          Object.keys(coverage).some(key => {
            if (key in coverage) {
              this.formData[key] = coverage[key]
            }
          })
          let obj = {
            optional_benefit: '',
            personal_liability: '',
            personal_accident: '',
            home_contents_all_risk: '',

            //business
            employees_compensation_insurance: '',
            public_liability_insurance: '',
            fidelity_guarantee_insurance: '',
            personal_accident_assault_insurance: '',
            money_insurance: '',
            business_interruption_insurance: '',
            contents: ''
          }
          Object.keys(obj).some(key => {
            if (key in coverage) {
              Object.keys(coverage[key]).some(item => {
                if (item in coverage[key]) {
                  this.formData[key][item] = coverage[key][item]
                }
              })
            }
          })
          if(this.productID==15)
            this.setEcPremium(this.formData.employees_compensation_insurance.premium)
          this.setHomePremium(null)
          this.setDomesticPremium(null)
        }
      }
    },
    coverageData:{
      deep: true,
      immediate: true,
      handler(val) {
        if(val)
        {
          let obj = {
            //business
            employees_compensation_insurance:'',
            public_liability_insurance:'',
            fidelity_guarantee_insurance:'',
            personal_accident_assault_insurance:'',
            money_insurance:'',
            business_interruption_insurance:'',
            contents:''
          }
          Object.keys(obj).some(key => {
            if (key in val) {
              Object.keys(val[key]).some(item => {
                if (item in val[key]) {
                  this.formData[key][item] = val[key][item]
                }
              })
            }
          })
        }
      }
    },
    riskPremium:{
      deep: true,
      immediate: true,
      handler(val) {
        if(val) {
          let {premium_third_party,premium_material_damage} =val
          this.formData.premium_third_party = premium_third_party
          this.formData.premium_material_damage = premium_material_damage
        }
      }
    }
  },
  methods:{
    ...mapActions('coverage', ['setEcPremium','setConstructionPremium','setPremium','setRiskPremium','setDomesticPremium','setHomePremium']),
    handlerConstraction(){
      let premium = this.formData.premium
      this.setConstructionPremium(Number(premium))
    },
    handlerHomePremium(){
      let params ={
        home_contents_all_risk:this.formData.home_contents_all_risk.premium||0,
        personal_accident:this.formData.personal_accident.premium||0,
        personal_liability:this.formData.personal_liability.premium||0,
        domestic_helpers_premium:0,
        other_premium:0,
        building_premium:0,
      }
      if(this.formData.optional_benefit.domestic_helpers==1)
        params.domestic_helpers_premium = this.formData.optional_benefit.domestic_helpers_premium
      if(this.formData.optional_benefit.building==1)
        params.building_premium = this.formData.optional_benefit.building_premium
      if(this.formData.optional_benefit.other==1)
        params.other_premium = this.formData.optional_benefit.other_premium
      this.setHomePremium(params)
    },
    handlerChange(){
      if(this.formData.optional_benefit.domestic_helpers==1){
        this.setDomesticPremium({
          domestic_helpers_premium:this.formData.optional_benefit.domestic_helpers_premium
        })
      }else{
        this.setDomesticPremium({domestic_helpers_premium:0})
      }
      this.handlerHomePremium()
    },
    handlerPremium(){
      let obj = {
        //business
        employees_compensation_insurance:'',
        public_liability_insurance:'',
        fidelity_guarantee_insurance:'',
        personal_accident_assault_insurance:'',
        money_insurance:'',
        business_interruption_insurance:'',
        contents:'',
      }
      let premium = 0
      Object.keys(obj).some(key => {
        premium = Number(this.formData[key].premium)+premium
      })
      if(this.productID==15)
        this.setEcPremium(this.formData.employees_compensation_insurance.premium)
      this.setPremium(premium)
    },
    handlerRisk(){
      // section_1
      // premium_material_damage
      // section_2
      // premium_third_party
      let params = {}
      if(this.formData.section_1==0)
        params.premium_material_damage = 0
      else params.premium_material_damage = this.formData.premium_material_damage
      if(this.formData.section_2==0)
        params.premium_third_party = 0
      else params.premium_third_party = this.formData.premium_third_party
      params.section_1 = this.formData.section_1
      params.section_2 = this.formData.section_2
      this.setRiskPremium(params)

    },
    async submitForm(formName) {
      await new Promise((resolve, reject) => {
        this.$refs['form'].validate(valid => {
          if(valid) {
            let coverage = {
              coverage:{...this.formData}
            }
            this.$emit('getFormData', formName, coverage)
            resolve(valid)
          } else {
            reject(valid)
          }
        })
      })
    },
    //提交给父类
    handlerPolicyData(){
      let coverage = {
        coverage:{...this.formData}
      }
      this.$emit('coverageData',coverage)
    },
  }
}
</script>

<style scoped lang="scss">
  .flex-inline{
    .form-item{
      display: flex;
      margin-bottom: 16px;
      align-items: center;
      span{
        flex: 1;
      }
      .el-form-item{
        flex: 2;
        margin-right: 15px;
      }

    }
    .hr-inline{
      margin:20px 10px;
    }
    .form-item-flex{
      .span-none{
        flex: 1;
      }
      span{
        flex:2
      }
    }
  }
</style>
